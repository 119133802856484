import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";
import {StorageService} from "../../service/storage.service";
import {User} from "../../interface/users/user";
import * as XLSX from "xlsx";
import {PurchasingList} from "../../interface/inbound/purchasing-list";
import {Receiver} from "../../interface/receiver/receiver";
import {ReceiverService} from "../../service/receiver/receiver.service";

@Component({
  selector: 'app-receiver-import',
  templateUrl: './receiver-import.page.html',
  styleUrls: ['./receiver-import.page.scss'],
})
export class ReceiverImportPage implements OnInit {
  public userInfo: User;
  data: Receiver[];
  // 导入数据的长度
  dataLength = 0;


  constructor(
    public modalController: ModalController,
    public storageService: StorageService,
    public receiverService:ReceiverService,

  ) { }

  ngOnInit() {
    this.getUserInfo();
  }

  onFileChange(evt: any) {
    // console.log('选择文件');

    const target:DataTransfer = <DataTransfer>(evt.target);
    // if(target.files.length !==1) throw new Error('can not use multiple files');

    const reader:FileReader = new FileReader();

    reader.onload = (e:any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: "binary"});
      const wsname: string = wb.SheetNames[0];
      const ws:XLSX.WorkSheet = wb.Sheets[wsname];
      // console.log(ws);
      this.data = (XLSX.utils.sheet_to_json(ws, {header: 2}));
      console.log(this.data);
      this.dataLength = this.data.length;
    }
    reader.readAsBinaryString(target.files[0]);
  }


  importData() {
    for (let item of this.data) {
      item.client = this.userInfo.client;
      // console.log(item.client);
      // console.log(item);
      this.receiverService.addReceiver(item).subscribe(value => {
        this.dataLength = this.dataLength - 1;
        console.log(this.dataLength);
        if (!this.dataLength) {
          console.log('剩余数据为0');
          this.dismiss();
        }
        // console.log(' add success');
        // console.log(value);
      });
    }
  }

  getUserInfo(){
    this.storageService.getUserInfo().then(user=>{
      // console.log(user);
      this.userInfo = user;
      // console.log(this.userInfo)
      // console.log(this.userInfo.client)
    });
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    }).then();
  }

}
