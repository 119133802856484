import {Component, Input, OnInit} from '@angular/core';
import {PurchasingList} from "../../interface/inbound/purchasing-list";
import {ModalController} from "@ionic/angular";
import {PurchasingListService} from "../../service/inbound/purchasing-list.service";
import {RouterService} from "../../service/router/router.service";
import * as XLSX from "xlsx";

@Component({
  selector: 'app-purchase-import',
  templateUrl: './purchase-import.page.html',
  styleUrls: ['./purchase-import.page.scss'],
})
export class PurchaseImportPage implements OnInit {
  data: PurchasingList[];
  // 导入数据的长度
  dataLength = 0;
  @Input() inboundBatchId: string;

  constructor(
    public modalController: ModalController,
    public inboundPlanService: PurchasingListService,
    public routerService: RouterService,
  ) { }

  ngOnInit() {
    // console.log(this.inboundBatchId);
  }


  onFileChange(evt: any) {
    // console.log('选择文件');

    const target:DataTransfer = <DataTransfer>(evt.target);
    // if(target.files.length !==1) throw new Error('can not use multiple files');

    const reader:FileReader = new FileReader();

    reader.onload = (e:any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: "binary"});
      const wsname: string = wb.SheetNames[0];
      const ws:XLSX.WorkSheet = wb.Sheets[wsname];
      // console.log(ws);
      this.data = (XLSX.utils.sheet_to_json(ws, {header: 2}));
      // console.log(this.data);
      this.dataLength = this.data.length;
    }
    reader.readAsBinaryString(target.files[0]);
  }

  importData() {
    for (let item of this.data) {
      item.purchaseOrder = this.inboundBatchId;
      // console.log(item);
      this.inboundPlanService.addPurchasingList(item).subscribe(value => {
        this.dataLength = this.dataLength - 1;
        // console.log(this.dataLength);
        if (!this.dataLength) {
          // console.log('剩余数据为0');
          this.dismiss();
        }
        // console.log(' add success');
        // console.log(value);
      });
    }
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    }).then();
  }
}
