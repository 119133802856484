import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {RouterService} from "../../service/router/router.service";
import {ModalController} from "@ionic/angular";
import {ConstantService} from "../../service/constant.service";
import {TranslateService} from "@ngx-translate/core";
import {PurchaseOrderService} from "../../service/inbound/purchase-order.service";
import {InboundOrderService} from "../../service/inbound/inbound-order.service";
import {WarehouseService} from "../../service/warehouse/warehouse.service";
import {InboundTruckOrderService} from "../../service/truck/inbound-truck-order.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-inbound-order-add',
  templateUrl: './inbound-order-add.page.html',
  styleUrls: ['./inbound-order-add.page.scss'],
})
export class InboundOrderAddPage implements OnInit {
  public imagePath;
  imgURL: any;
  public message: string;
  public loading: boolean;
  public purchaseOrderId: any;

  formGroup: FormGroup;
  public warehouseList: any;
  public inboundTruckOrderList: any;


  constructor(
    public routerService: RouterService,
    public modalController: ModalController,
    private constantService: ConstantService,
    private translateService: TranslateService,
    private fb: FormBuilder,
    public purchaseOrderService: PurchaseOrderService,
    public inboundOrderService: InboundOrderService,
    public warehouseService: WarehouseService,
    public inboundTruckOrderService: InboundTruckOrderService,
    private route: ActivatedRoute,

  ) { }


  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    }).then();
  }

  ngOnInit() {
    this.initPage();
  }

  initPage() {
    // 获取路由传过来的数据
    this.route.queryParams.subscribe(
      params => {
        this.purchaseOrderId = params.inboundBatchId ? params.inboundBatchId : NaN;
        // console.log(this.purchaseOrderId)
        // inboundDeliveryOrderId
      }
    );
    this.initForm();
    this.getWarehouseList();
    this.getInboundTruckOrderList();

  }

  // 获取仓库列表
  getWarehouseList() {
    this.warehouseService.getWarehouseList().subscribe(value => {
      if(value.results) {
        this.warehouseList = value.results;
        // console.log('warehouse');
        // console.log(this.warehouseList);
      }
    });

  }
  // 获取仓库列表
  getInboundTruckOrderList() {
    this.inboundTruckOrderService.getInboundTruckOrderList().subscribe(value => {
      if(value.results) {
        this.inboundTruckOrderList = value.results;
        // console.log(this.inboundTruckOrderList);
      }
    });

  }


  // 表单数据初始化
  initForm() {
    this.formGroup = this.fb.group(
      {
        purchaseOrder: [this.purchaseOrderId, ],
        warehouse: ['', Validators.required],
        inboundTruckOrder: ['', Validators.required],
        // startTime: [],
        // endTime: [],
        // addBy: [],
        // addTime: [],
        // editTime: [],

      }
    );
  }

  // 提交表单
  onSubmit() {
    this.loading = true;
    // 数据格式化
    const formData = new FormData();
    formData.append('inboundBatch', this.purchaseOrderId);
    // formData.append('code', this.formGroup.get('code').value);
    // formData.append('startTime', this.formGroup.get('startTime').value);
    // formData.append('endTime', this.formGroup.get('endTime').value);

    // 判断类型是否为空
    if (this.formGroup.get('warehouse').value) { formData.append('warehouse', this.formGroup.get('warehouse').value); }
    // 判断是否有父类
    // if (this.formGroup.get('parent_category').value) { formData.append('parent_category', this.formGroup.get('parent_category').value); }

    // 判断是否有照片
    // if (this.formGroup.get('category_picture').value) { formData.append('category_picture', this.formGroup.get('category_picture').value); }

    // 向后台提交数据
    this.inboundOrderService.addInboundOrder(this.formGroup.value).subscribe(
      (value) => {
        this.loading = false;
        // console.log(value);
        // 将添加的数据加入到列表中
        // this.adminCategoryListViewService.goodsCategoryList.push(value);
        // 提示添加成功
        this.constantService.presentToast(this.translateService.instant('addSuccess')).then();
        // 导航到列表页面
        this.routerService.toBack();
      },
      () => {
        this.loading = false;
        this.constantService.presentToast(this.translateService.instant('addFailed')).then();
      },
    );

  }



}
