import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {ConstantService} from "../constant.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class InboundOrderService {
  private readonly HOST = this.remoteHost.remoteHost();
  private readonly URL = this.HOST + 'InboundOrder/';
  private readonly URL_FULL = this.HOST + 'InboundOrderFull/';


  constructor(public httpClient: HttpClient, public remoteHost: ConstantService) { }

  // 提取所有入库批次
  getInboundOrderList(page = 1, pageSize = 20, ): Observable<any> {

    const params = new HttpParams()
      .set('page', page.toString())
      .set('page_size', pageSize.toString());

    return this.httpClient.get<any>(this.URL, {params, headers: this.remoteHost.headersIncludeToken});
  }

  // 提取指定批次信息 含客户信息
  getInboundOrderFullById(id: any ): Observable<any> {

    const params = new HttpParams()
      .set('id', id.toString());

    return this.httpClient.get<any>(this.URL_FULL + id + '/', {params, headers: this.remoteHost.headersIncludeToken});
  }
  // 提取所有入库批次
  getInboundOrderListByPurchaseOrderId(PurchaseOrderId:string, page = 1, pageSize = 20, ): Observable<any> {

    const params = new HttpParams()
      .set('purchaseOrder', PurchaseOrderId.toString())
      .set('page', page.toString())
      .set('page_size', pageSize.toString());

    return this.httpClient.get<any>(this.URL, {params, headers: this.remoteHost.headersIncludeToken});
  }

  // 提取所有入库批次
  getInboundOrderListFullByPurchaseOrderId(PurchaseOrderId:string, page = 1, pageSize = 20, ): Observable<any> {

    const params = new HttpParams()
      .set('purchaseOrder', PurchaseOrderId.toString())
      .set('page', page.toString())
      .set('page_size', pageSize.toString());

    return this.httpClient.get<any>(this.URL_FULL, {params, headers: this.remoteHost.headersIncludeToken});
  }


  // 提取指定批次信息 含客户信息
  getInboundOrderListById(id: any ): Observable<any> {

    const params = new HttpParams()
      .set('id', id.toString());

    return this.httpClient.get<any>(this.URL + id + '/', {params, headers: this.remoteHost.headersIncludeToken});
  }

  // 添加商品类别
  addInboundOrder(inboundOrder: any): Observable<any> {
    return this.httpClient.post<any>(this.URL, inboundOrder, {headers: this.remoteHost.headersIncludeToken});
  }
}
